import { triggerEvent } from 'utilities/dom';

import { schemes } from '../schemes';
import { getDefaultBoxTitleFromScheme } from '../utilities';

import getLayoutContentFromScheme from './getLayoutContentFromScheme';
import setPdpAnchorDynamically from './setPdpAnchorDynamically';
import renderLayoutContent from './renderLayoutContent';

function loadCertonaItems (widget, scheme, doNotReplaceExistingItems, boxTitle, widgets) {

	// Adding this check for covering the case if page is categorylanding but scheme is used as sortpage_rr
	if (
		$('.suggestedProductsContainer').filter(`[data-scheme="${scheme}_rr"]`).length === 0
		&& scheme === 'categorylanding'
		&& $('.suggestedProductsContainer').filter('[data-scheme="sortpage_rr"]').length > 0
	) {

		scheme = 'sortpage';

	}

	const recentlyViewedItemsRegex = /^GF_/i;
	const recentlyViewedHomePage = scheme.toLowerCase().search(schemes.GF_HOME) > -1;
	const recentScheme = recentlyViewedItemsRegex.test(scheme);
	const isLastCategoryScheme = /^lastCategory/i.test(scheme);
	const $suggestedProductsContainer = $('.suggestedProductsContainer').filter(`[data-scheme="${scheme}_rr"]`);

	const $container = recentScheme ? $('.recentlyViewedWrapper') : $suggestedProductsContainer;
	const isRelatedScheme = scheme === schemes.RELATED;
	const $anchorContainer = $('.sectionAnchors').find('[data-scheme="related_rr"]');
	const certonaTitleText = boxTitle || getDefaultBoxTitleFromScheme(scheme, boxTitle);
	const $certonaTitleSelector = recentScheme
		? $('.jsRecentlyViewedTitle')
		: $suggestedProductsContainer.find('.jsCertonaTitle');
	let layoutContent = getLayoutContentFromScheme(scheme, widget, widgets);

	// initialize certona title
	if (certonaTitleText) {

		$certonaTitleSelector.text(certonaTitleText);
		if ($suggestedProductsContainer) {

			const viewMorebtn = $suggestedProductsContainer.find('.moreViewButton');
			if (viewMorebtn) {

				viewMorebtn.removeClass('hidden');

			}

		}

	} else {

		// remove title if empty
		$certonaTitleSelector.remove();

	}

	if (doNotReplaceExistingItems && $('.pdRelItmsProdShadeOptions, .pdRelItmsProd').length > 0) {

		$container.addClass('loaded');
		if (isRelatedScheme) {

			$anchorContainer.removeClass('hidden');
			if (certonaTitleText) {

				setPdpAnchorDynamically({ $anchorContainer, certonaTitleText });

			}

		}

	} else {

		const isOfSort =			[
			'Chandeliers',
			'Lamp Shades',
			'outdoor decor',
			'home textiles',
			'landscape lighting',
			'ceiling medallions'
		].indexOf(window.resx.RecommendedItemsRequest.Criteria.Category) > -1;

		if (!layoutContent) {

			if (recentScheme) {

				$container.attr('data-scheme', `${scheme}_rr`);

				layoutContent = getLayoutContentFromScheme(schemes.RECENTLY_VIEWED_ITEMS, widget);

			} else if (isOfSort) {

				layoutContent = getLayoutContentFromScheme(schemes.SORT_PAGE, widget);

			} else if (!isLastCategoryScheme) {

				layoutContent = getLayoutContentFromScheme(schemes.GENERIC_HORIZONTAL, widget);

			}

		}

		if (layoutContent) {

			if (recentlyViewedHomePage) {

				renderLayoutContent(scheme, layoutContent);

			} else if (recentScheme) {

				renderLayoutContent(schemes.RECENTLY_VIEWED_ITEMS, layoutContent);

			} else if (isOfSort) {

				renderLayoutContent(scheme === schemes.CATEGORY_LANDING_02 ? scheme : schemes.SORT_PAGE, layoutContent);

			} else {

				renderLayoutContent(scheme, layoutContent);

			}

		}

		if (isRelatedScheme) {

			$anchorContainer.removeClass('hidden');
			if (certonaTitleText) {

				setPdpAnchorDynamically({ $anchorContainer, certonaTitleText });

			}

		}
		$container.addClass('loaded');

		triggerEvent.call(document, `recommendedItems:${scheme}:loaded`);

		const recentItems = document.querySelector('.recentlyViewedWrapper .jsRecentlyViewedTitle');
		if (recentItems && recentItems.innerText === '') {

			$('.recentlyViewedWrapper>.group').remove();

		}

	}

}

export default loadCertonaItems;
