import { delayedCall } from 'utilities/function';
import { utagLinkEasyAskSearch, utagLinkSearch } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import redirectToProduct from './redirectToProduct';
import redirectToSearch from './redirectToSearch';
import SearchAutocompleteModel from './SearchAutocompleteModel';

function SearchAutocomplete (input, opts) {

	const model = new SearchAutocompleteModel({ api: opts.api });

	const view = {
		/**
		 * Prepare parent node and attach it to DOM
		 */
		createAndAttachParentNode () {

			// to resolve IE11 bug for flexbox we have to create wrapper for parent node
			const ie11Wrapper = document.createElement('div');
			ie11Wrapper.className = 'ie11-wrapper';

			// create parent node and put it in wrapper
			const parent = document.createElement('div');
			parent.className = 'searchdropdown-root';
			parent.style.display = 'none';
			ie11Wrapper.appendChild(parent);

			// attach wrapper to DOM
			(this.opts.output ? this.opts.output : this.input.parentElement).appendChild(ie11Wrapper);

			return parent;

		},
		inDontHideList (node) {

			for (let i = 0; i < this.opts.dontHideOn.length; i++) {

				if (this.opts.dontHideOn[i].contains(node)) {

					return true;

				}

			}

			return false;

		},
		onMouseUpBody (e) {

			if (view.inDontHideList(e.target)) {

				view.dontHide = false;

			} else {

				view.hide();

			}

		},
		onMouseDownBody (e) {

			if (view.inDontHideList(e.target)) {

				view.dontHide = true;

			}

		},
		/**
		 * Bind rules when to hide UI
		 */
		bindHideRules () {

			document.body.addEventListener('mousedown', this.onMouseDownBody, false);
			document.body.addEventListener('mouseup', this.onMouseUpBody, false);

		},
		/**
		 * UnBind rules when to hide UI
		 */
		unBindHideRules () {

			document.body.removeEventListener('mousedown', this.onMouseDownBody, false);
			document.body.removeEventListener('mouseup', this.onMouseUpBody, false);

		},
		onKeydown (e) {

			switch (e.which) {

				/* ESC */
				case 27:
					e.preventDefault();
					this.hide();
					break;
				/* ENTER */
				case 13:
					e.preventDefault();
					const recentSearchesWrapper = document.querySelector('.ie11-wrapper.recentsearches');
					// !recentSearchesWrapper will work when recent searches are not available
					const recentSearchDrowdownHidden =						!recentSearchesWrapper
						|| (recentSearchesWrapper && recentSearchesWrapper.classList.contains('hidden'));

					if (recentSearchDrowdownHidden) {

						if (this.model.suggestions[this.model.getActiveSuggestionIndex()]) {

							this.trackActiveSuggestionSubmit(
								this.model.suggestions[this.model.getActiveSuggestionIndex()]
							);

						} else {

							this.trackSearchSubmit(e.target.value);

						}
						this.selectSuggestion();

					}
					break;
				/* UP */
				case 38:
					e.preventDefault();
					this.renderLoaderInProducts();
					this.model
						.setActiveSuggestion(this.model.getActiveSuggestionIndex() - 1, this.opts.show.products)
						.then(() => this.changeActiveSuggestion(true));
					break;
				/* DOWN */
				case 40:
					e.preventDefault();
					this.renderLoaderInProducts();
					this.model
						.setActiveSuggestion(this.model.getActiveSuggestionIndex() + 1, this.opts.show.products)
						.then(() => this.changeActiveSuggestion(true));
					break;
				default:
					break;

			}

		},
		onInput () {

			if (this.canShow()) {

				this.model.resetModel(this.input.value);
				this.debounceUpdate(this.input.value).catch(() => {});

			} else {

				this.debounceUpdate.cancel();
				this.hide();

			}

		},
		onBlur () {

			if (!this.dontHide) {

				this.hide();

			}

		},
		handleKeyDown: (e) => {

			view.onKeydown(e);

		},
		handleInput: () => {

			view.onInput();

		},
		handleBlur: () => {

			view.onBlur();

		},
		onMouseOverSuggestion (e) {

			const index = [].slice
				.call(e.currentTarget.parentElement.parentElement.children)
				.indexOf(e.currentTarget.parentElement);
			if (this.model.getActiveSuggestionIndex() !== index - 1) {

				this.model
					.setActiveSuggestion(index - 1, this.opts.show.products)
					.then(() => this.changeActiveSuggestion());

			}

		},
		onClickSuggestion () {

			this.trackActiveSuggestionSubmit(this.model.suggestions[this.model.getActiveSuggestionIndex()]);

			if (!window.getSelection().toString()) {

				this.selectSuggestion();

			}

		},
		onClickPopularProduct () {

			this.model.setActiveProduct(i);
			this.selectProduct();

		},
		changeActiveSuggestion (changeDueToKeyPress = false) {

			const activeSuggestion = this.model.getActiveSuggestion().keyWord.toLocaleLowerCase();
			const suggestionsContainer = this.getSuggestionsContainer();
			const currentActive =				(suggestionsContainer
					&& suggestionsContainer.querySelector('.searchdropdown-root__suggestion_active'))
				|| null;
			const suggestionsNodes =				(suggestionsContainer && suggestionsContainer.querySelectorAll('.searchdropdown-root__suggestion'))
				|| null;
			const newActive = suggestionsNodes ? suggestionsNodes[this.model.getActiveSuggestionIndex()] : null;
			const productsContainer = this.getProductsContainer();

			if (activeSuggestion.length) {

				if (!this.opts.doNotFillTextOnHover) this.input.value = this.model.getActiveSuggestion().keyWord.toLocaleLowerCase();
				this.input.focus();

			}

			if (currentActive) {

				currentActive.classList.remove('searchdropdown-root__suggestion_active');
				if (this.opts.doNotFillTextOnHover) {

					const currentActiveWrapper = currentActive.closest('.searchdropdown-root__suggestion-wrapper');
					currentActiveWrapper?.classList.remove('keyActive');
					currentActiveWrapper?.classList.remove('hoverActive');

				}

			}

			if (newActive) {

				newActive.classList.add('searchdropdown-root__suggestion_active');
				if (this.opts.doNotFillTextOnHover) {

					const newActiveWrapper = newActive.closest('.searchdropdown-root__suggestion-wrapper');
					newActiveWrapper?.classList.add(changeDueToKeyPress ? 'keyActive' : 'hoverActive');

				}

			}

			// replace popular products for new active suggestion
			if (this.opts.show.products && productsContainer) {

				this.parent.replaceChild(this.renderProducts(this.model.getData()), productsContainer);

			}

		},
		selectProduct () {

			redirectToProduct(this.model.getActiveProduct().productUrl);

		},
		selectSuggestion () {

			const suggestion = this.model.getActiveSuggestion();
			if (suggestion.keyWord.length > 0) {

				if (this.opts.doNotFillTextOnHover) this.input.value = suggestion.keyWord;
				redirectToSearch(suggestion.searchUrl);

			}

		},
		/**
		 * Get suggestions from backend and then render
		 * @param {string} search
		 */
		update (search) {

			if (window.lp.globals.pssProductSuggestionConsoleEnabled) {

				this.model
					.getSuggestionsWithoutProduct(search)
					.then(() => this.render())
					.catch(() => this.renderLoader(this.parent));

			}
			this.model
				.getSuggestions(search, this.opts.show)
				.then(() => this.render())
				.catch(() => this.renderLoader(this.parent));

		},
		/**
		 * Render whole UI
		 */
		render () {

			this.cleanUpDom(this.parent);

			if (this.hasDataToShow()) {

				const domFragment = document.createDocumentFragment();
				domFragment.appendChild(this.renderSuggestions(this.model.getData()));
				if (this.opts.show.products) {

					domFragment.appendChild(this.renderDelimeter());
					domFragment.appendChild(this.renderProducts(this.model.getData()));

				}

				this.parent.appendChild(domFragment);
				this.show();

			} else {

				this.hide();

			}

		},
		renderDelimeter () {

			const delimeter = document.createElement('div');
			delimeter.className = 'searchdropdown-root__delimeter';
			return delimeter;

		},
		/**
		 * Render suggestions
		 * @param {object} data
		 */
		renderSuggestions (data) {

			const parent = document.createElement('div');
			let element;
			let wrapper;

			parent.className = 'searchdropdown-root__suggestions';
			if (data && data.suggestions && data.suggestions.length > 0) {

				wrapper = document.createElement('div');
				wrapper.className = 'searchSuggestionCount sr-only';
				wrapper.setAttribute('aria-live', 'assertive');
				wrapper.innerText = `${data.suggestions.length} suggestions are available, use up and down arrow to navigate them.`;
				parent.appendChild(wrapper);

			}

			for (let i = 0; i < data.suggestions.length; i += 1) {

				const suggestionDoctype = data.suggestions[i].docType;
				wrapper = document.createElement('div');
				wrapper.className = 'searchdropdown-root__suggestion-wrapper';
				element = document.createElement('div');
				element.className = 'searchdropdown-root__suggestion';
				element.dataset.docType = suggestionDoctype;

				if (i === data.activeSuggestionIndex) {

					element.classList.add('searchdropdown-root__suggestion_active');

				}

				if (data.suggestions[i].name) {

					element.classList.add('searchdropdown-root__suggestion_infield');
					element.appendChild(document.createTextNode(`in ${data.suggestions[i].value}`));

				} else {

					element.appendChild(this.getFormattedText(data.suggestions[i].keyWord, data.query));

				}

				element.addEventListener('mouseover', (e) => this.onMouseOverSuggestion(e), false);
				element.addEventListener(
					'click',
					(e) => this.onClickSuggestion(e, {
						searchTerm: data.search,
						selectedQuery: data.suggestions[i].keyWord,
						docType: suggestionDoctype
					}),
					false
				);

				wrapper.appendChild(element);
				parent.appendChild(wrapper);

			}

			return parent;

		},
		/**
		 * Render only products
		 * @param {object} data
		 */
		renderProducts (data) {

			const parent = document.createElement('div');
			const msgDiv = document.createElement('div');

			parent.className = 'searchdropdown-root__products';

			msgDiv.className = 'searchdropdown-root__message';
			msgDiv.appendChild(
				this.getFormattedText(
					`Top Results for “${this.model.getActiveSuggestion().keyWord.toLocaleLowerCase()}”`,
					data.query
				)
			);

			if (data.products.length) {

				parent.appendChild(msgDiv);

			}

			for (let i = 0; i < data.products.length; i++) {

				const div = document.createElement('div');
				div.className = 'searchdropdown-root__product';
				div.dataset.track = `popularProduct-${data.products[i].shortSku}`;
				const img = document.createElement('img');
				const divTitle = document.createElement('div');

				divTitle.innerHTML = data.products[i].productName;
				divTitle.className = 'searchdropdown-root__product-title';

				img.src = data.products[i].imageUrl;
				img.className = 'searchdropdown-root__product-image';
				img.setAttribute('alt', '');

				div.appendChild(img);
				div.appendChild(divTitle);
				div.dataset.i = i;
				div.dataset.shortSku = data.products[i].shortSku;
				div.addEventListener(
					'click',
					(e) => this.onClickPopularProduct(e, {
						searchTerm: data.search,
						suggestionIndex: data.activeSuggestionIndex,
						docType:
								data.activeSuggestionIndex === -1 && data.products[i].docType != null
									? data.products[i].docType
									: data.suggestions[data.activeSuggestionIndex].docType
					}),
					false
				);

				parent.appendChild(div);

			}

			return parent;

		},
		/**
		 * Render loader in the products container
		 */
		renderLoaderInProducts () {

			const container = this.getProductsContainer();
			if (container) {

				this.renderLoader(container);

			}

		},
		/**
		 * Render loader in the container
		 */
		renderLoader (container) {

			const loader = document.createElement('span');

			loader.className = 'lpIcon-loading';
			this.cleanUpDom(container);
			container.appendChild(loader);

		},
		getSuggestionsContainer () {

			return this.parent.children[0];

		},
		getProductsContainer () {

			return this.parent.children[2];

		},
		inputHasFocus () {

			return document.activeElement === this.input;

		},
		getFormattedText (str, search) {

			let split;
			const domFragment = document.createDocumentFragment();

			if (search) {

				// escape all regex symbols then split by search term
				split = str.split(new RegExp(`(${search.trim().replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')})`, 'gi'));

			} else {

				split = [ str ];

			}

			// wrap <strong> tag around all occurrences of the search term
			if (split.length === 1) {

				const searchKeyword = search.replace(/[-[\]/{}()*+?.\\^$|]/g, ' ').toLowerCase();
				const suggestion = split[0];

				suggestion.split(' ').forEach((s) => {

					if (searchKeyword.includes(s.toLowerCase())) {

						domFragment.appendChild(document.createTextNode(`${s} `));

					} else {

						domFragment
							.appendChild(document.createElement('strong'))
							.appendChild(document.createTextNode(`${s} `));

					}

				});

			} else {

				for (let i = 0; i < split.length; i += 1) {

					if (split[i]) {

						if (i % 2 === 0) {

							domFragment
								.appendChild(document.createElement('strong'))
								.appendChild(document.createTextNode(split[i]));

						} else {

							domFragment.appendChild(document.createTextNode(split[i]));

						}

					}

				}

			}

			return domFragment;

		},
		canShow () {

			return this.input.value.length >= this.opts.minLetters;

		},
		hasDataToShow () {

			return this.model.getData().suggestions.length > 0;

		},
		cleanUpDom (domElement) {

			while (domElement && domElement.firstChild) {

				domElement.removeChild(domElement.firstChild);

			}

		},
		hide () {

			this.parent.style.display = 'none';
			this.model.resetModel(this.input.value);
			this.unBindHideRules();

		},
		show () {

			this.parent.style.removeProperty('display');
			this.bindHideRules();

		},
		tearDown () {

			this.unBindHideRules();

			view.input.removeEventListener('keydown', view.handleKeyDown, false);
			view.input.removeEventListener('input', view.handleInput, false);
			view.input.removeEventListener('blur', view.handleBlur, false);

		},
		trackActiveSuggestionSubmit (suggestion) {

			if (!suggestion) {

				return;

			}

			const { keyWord } = suggestion;

			if (keyWord) {

				utagLinkEasyAskSearch()({
					eventAction: 'SAYT-SelectSubmit',
					eventLabel: keyWord.toLowerCase()
				});
				setGenericEvent({ event_name: 'search_sayt_select', search_term: keyWord });

			}

		},
		trackSearchSubmit (searchKeyword) {

			utagLinkSearch()({
				eventAction: 'Search-Submit',
				eventLabel: searchKeyword
			});

		}
	};

	view.input = input;
	view.opts = opts;
	view.model = model;
	view.opts.dontHideOn = opts.dontHideOn || [];

	view.debounceUpdate = delayedCall(view, view.update, opts.debounceWait);

	// create parent node and attach it to DOM
	view.parent = view.createAndAttachParentNode();
	// add parent node to exception list to prevent hide on clicks in the widget UI
	view.opts.dontHideOn.push(view.parent);
	// apply rules when to hide widget UI
	view.bindHideRules();

	view.input.setAttribute('autocomplete', 'off');
	view.input.addEventListener('keydown', view.handleKeyDown, false);
	view.input.addEventListener('input', view.handleInput, false);
	view.input.addEventListener('blur', view.handleBlur, false);

	if (view.inputHasFocus() && view.canShow()) {

		// trigger input event if we already have some text and focus
		const event = document.createEvent('Event');
		event.initEvent('input', false, true);
		view.input.dispatchEvent(event);

	} else {

		view.hide();

	}

	return view;

}

export default SearchAutocomplete;
